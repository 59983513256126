/* eslint-disable no-underscore-dangle */
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeroWithTitle from '../components/HeroWithTitle';
// import Button from '../components/Button';
import { introStyle } from '../styles/global';
import breakpoints from '../styles/breakpoints';
import StampFormation from '../images/StampFormation';
import colors from '../styles/colors';
// import WithSidebar from '../components/WithSidebar';
import breakpointsRange from '../utils/breakpointsRange';
import FormationList from '../views/FormationsPage/FormationsList';
import responsiveStyle from '../utils/responsiveStyle';

// import vectorExperience from '../images/vectorExperience.svg';

const IntroSection = styled.section`
  max-width: 1440px;
  margin: auto;

  text-align: center;

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [40, 120] },
      { prop: 'marginBottom', sizes: [68, 140] },
      { prop: 'paddingLeft', sizes: [28, 40, undefined, 140] },
      { prop: 'paddingRight', sizes: [28, 40, undefined, 140] },
    ],
    breakpoints.spacings
  )};
`;

const TitleWrapper = styled.div`
  display: flex;

  text-transform: uppercase;

  h1 {
    flex-basis: 0;

    margin: 0;

    color: ${colors.white};
    line-height: ${35 / 30};

    word-break: initial;

    ${breakpointsRange(
      [{ prop: 'fontSize', sizes: [30, 56] }],
      breakpoints.fonts
    )};
  }
`;

const ListSection = styled.section`
  max-width: ${1200 + 240}px;
  margin: auto;
  padding: 0;

  ${responsiveStyle('paddingLeft', [28, 80, null, 120], breakpoints.spacings)};
  ${responsiveStyle('paddingRight', [28, 80, null, 120], breakpoints.spacings)};
`;

// const DetailSection = styled.section`
//   background-color: ${colors.azure};

//   ${breakpointsRange(
//     [
//       { prop: 'marginTop', sizes: [68, 140] },
//       { prop: 'marginBottom', sizes: [68, 140] },
//     ],
//     breakpoints.spacings
//   )};
// `;

// const DetailContainer = styled(WithSidebar)`
//   max-width: 1440px;
//   margin: auto;

//   ${breakpointsRange(
//     [
//       { prop: 'paddingTop', sizes: [68, 134] },
//       { prop: 'paddingBottom', sizes: [88, 140] },
//       { prop: 'paddingRight', sizes: [28, 40, 40, 120] },
//       { prop: 'paddingLeft', sizes: [28, 40, 40, 120] },
//     ],
//     breakpoints.spacings
//   )};
// `;

// const DetailIcon = styled.img`
//   min-width: 260px;
// `;

// const DetailTitle = styled.h2`
//   ${h1Style};
//   ${withFrontUnderline};

//   color: ${colors.black};
// `;

// const DetailTextWrapper = styled.div`
//   color: ${colors.white};

//   > *:first-child {
//     margin-top: 0;
//   }

//   > *:last-child {
//     margin-bottom: 0;
//   }
// `;

// const DetailButton = styled(Button)`
//   ${breakpointsRange(
//     [{ prop: 'marginTop', sizes: [48, 68] }],
//     breakpoints.fonts
//   )};
// `;

const Stamp = styled(StampFormation)`
  color: ${colors.azure};
`;

const FormationsPage = ({ data }) => {
  const {
    headerImageMobile: {
      childImageSharp: { fluid: headerImageMobile },
    },
    headerImageDesktop: {
      childImageSharp: { fluid: headerImageDesktop },
    },
    allSanityTraining: { edges: trainings },
  } = data;

  const reducedTraining = trainings.map(({ node }) => {
    let price = node.price.amount;
    let registry;

    if (node.price.pricingType === 'free') {
      price = 0;
    }

    if (node.registry && node.registry.registryType === 'online') {
      registry = {
        type: 'ONLINE',
      };
    }
    if (node.registry && node.registry.registryType === 'email') {
      registry = {
        type: 'EMAIL',
        href: node.registry.emailAddress
          ? `mailto:${node.registry.emailAddress}`
          : 'mailto:formations@maxilloquebec.com',
      };
    }

    if (node.registry && node.registry.registryType === 'url') {
      registry = {
        type: 'URL',
        href: node.registry.url,
      };
    }

    return {
      id: node.id,
      title: node.title,
      rawDescription: node._rawShortDescription,
      to: `/formations/${node.slug.current}`,
      fluid: node.featuredImage.asset.fluid,
      price,
      registry,
    };
  });

  return (
    <Layout hasNoHeaderSpacer>
      <SEO
        title='Accueil formation'
        description='Les chirurgiens des cliniques Maxillo Québec Lebourgneuf et Lévis participent à la formation continue de ses référents. Voici la liste de nos formations.'
      />

      <HeroWithTitle
        pictureSmall={headerImageMobile}
        pictureLarge={headerImageDesktop}
        renderStamp={<Stamp />}
        backgroundColor={colors.black}
      >
        <TitleWrapper>
          <h1>Formations</h1>
        </TitleWrapper>
      </HeroWithTitle>

      <IntroSection>
        <p css={introStyle}>
          L’équipe de Maxillo Québec est fière de participer à la formation
          continue de ses collaborateurs. Le partage des connaissances et les
          discussions entre les différents intervenants est une priorité chez
          Maxillo Québec. Nous vous présentons ici les formations à venir.
          Ouvertes à tous, elles garantissent une qualité de présentation à la
          hauteur de vos attentes.
        </p>
      </IntroSection>

      <ListSection>
        <FormationList items={reducedTraining} />
      </ListSection>

      {/* <DetailSection>
        <DetailContainer
          align='center'
          contentMin='54%'
          spaceVertical={[56, 104]}
          spaceHorizontal={[56, 104]}
          renderSidebar={<DetailIcon src={vectorExperience} />}
          side='right'
        >
          <DetailTextWrapper>
            <DetailTitle>
              Documents de formation pour les professionnels
            </DetailTitle>

            <p>
              Afin de bien outiller ses partenaires professionnels, Maxillo
              Québec met à leur disposition une foule de documents de formation.
              Ceux-ci sont facile à consulter et téléchargeables gratuitement en
              tout temps.est une priorité chez Maxillo Québec. Nous vous
              présentons ici les formations à venir. Ouvertes à tous, elles
              garantissent une qualité de présentation à la hauteur de vos
              attentes.
            </p>

            <p>
              Ces dernières sont ouvertes à tous et garantissent une qualité de
              présentation à la hauteur de vos attentes.
            </p>

            <DetailButton outlined>Consulter les documents</DetailButton>
          </DetailTextWrapper>
        </DetailContainer>
      </DetailSection> */}
    </Layout>
  );
};

FormationsPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default FormationsPage;

export const query = graphql`
  query {
    headerImageDesktop: file(name: { eq: "img-header-Formations-2520x2160" }) {
      ...HeroWithTitleLargePictureData
    }

    headerImageMobile: file(name: { eq: "img-header-Formations-2220x2766" }) {
      ...HeroWithTitleSmallPictureData
    }

    allSanityTraining {
      edges {
        node {
          slug {
            current
          }
          id
          title
          _rawShortDescription(resolveReferences: { maxDepth: 10 })
          featuredImage {
            asset {
              fluid(maxWidth: 600, maxHeight: 600) {
                srcSetWebp
                srcSet
                src
                aspectRatio
                base64
                sizes
              }
            }
          }
          price {
            pricingType
            amount
          }
          registry {
            emailAddress
            registryType
            url
          }
        }
      }
    }
  }
`;
