// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampFormation = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
    <RoundContainer>
      <path d='M20 78.4L4.6 73l.8-2.5L18.7 75l2.2-6.4 2.2.8z' />
      <path d='M28.7 58a2 2 0 01-1.2 1.1 2.7 2.7 0 01-1.6 0 3 3 0 011 2 4.3 4.3 0 01-.6 2.4 4.1 4.1 0 01-2.2 2.1 3.5 3.5 0 01-2.8-.3 3.2 3.2 0 01-1.9-2.4 6.8 6.8 0 011-4l1-2-1-.4a2.4 2.4 0 00-1.8-.3 2.6 2.6 0 00-1.4 1.5 3.1 3.1 0 00-.4 1.8 4.3 4.3 0 00.6 1.5l-2 .8a4.9 4.9 0 01-.7-2.3 5.6 5.6 0 01.7-3 5.4 5.4 0 012.6-2.7 4 4 0 013.4.3L27 57l.6-1.2 1.9 1zM25 61.9a3.5 3.5 0 00.4-2 1.6 1.6 0 00-1-1.4l-1.5-.8-1 2c-.8 1.5-.7 2.6.3 3l.3.3a1.5 1.5 0 001.4 0 2.6 2.6 0 001-1.1z' />
      <path d='M28.5 41.3l-1.2 1.4-1.6-1.4 1.2-1.4-1.5-1.4a3.3 3.3 0 01-1.3-2A3 3 0 0125 34l1.3-1.5L28 34l-1.7 1.9 2.4 2 1.6-1.9 1.7 1.4-1.7 1.9 7.7 6.7-1.7 2z' />
      <path d='M45.7 38.7a6 6 0 01-2 1.2 5 5 0 01-2.2.2 6 6 0 01-2.2-.8 8.8 8.8 0 01-3.5-4 5.8 5.8 0 01-.5-2.3 4.9 4.9 0 01.5-2.1 5.7 5.7 0 011.5-1.9 5.8 5.8 0 012-1.2 4.8 4.8 0 012.2-.2 5.7 5.7 0 012.2.8 9 9 0 013.5 4 6 6 0 01.5 2.3 5 5 0 01-.5 2.1 6 6 0 01-1.5 1.9zM44.3 37a2.8 2.8 0 001-2 3.3 3.3 0 00-1-2.4L43 31a3.4 3.4 0 00-2.2-1.3 3.2 3.2 0 00-3.2 2.8 3.3 3.3 0 001 2.4l1.4 1.5a3.3 3.3 0 002.2 1.3 2.8 2.8 0 002-.7z' />
      <path d='M53.2 32.6l-6.6-10.3 2.1-1.4 1.3 2h.1a3.3 3.3 0 010-2 3.5 3.5 0 011.6-1.9l.6-.4 1.3 2.1-.8.6A4.7 4.7 0 0051 23a1.6 1.6 0 00.1 1.7l4.2 6.6z' />
      <path d='M61.8 26.8l-4-11.6 2.5-.9.7 2h.1a5.6 5.6 0 01.2-1 3 3 0 01.4-1 2.9 2.9 0 01.7-.7 4 4 0 011-.6 4.2 4.2 0 012.3-.1 3.6 3.6 0 012 1.3 3.6 3.6 0 01.7-2 4 4 0 012.1-1.6 3.5 3.5 0 013.1.3 5.4 5.4 0 012.1 3l2.5 7.4-2.4.8-2.5-7.1a3.5 3.5 0 00-1.2-1.9 2 2 0 00-1.8-.1 3.6 3.6 0 00-1 .4 2.4 2.4 0 00-.5.6 2 2 0 00-.3.8 2 2 0 000 1l2.7 7.9-2.5.8-2.4-7.1q-.9-2.7-3-2a3.3 3.3 0 00-.8.4 2.6 2.6 0 00-.6.6 2 2 0 00-.4.9 2 2 0 00.1.9l2.6 7.8z' />
      <path d='M91.8 19.2a2 2 0 01-1.7-.4 2.6 2.6 0 01-.8-1.3h-.1a3 3 0 01-1 1.9 4.2 4.2 0 01-2.3.8 4 4 0 01-3-.6 3.6 3.6 0 01-1.4-2.5 3.2 3.2 0 011-3 7 7 0 013.8-1.3l2.2-.3-.1-1a2.4 2.4 0 00-.9-1.7 2.6 2.6 0 00-2-.3 3 3 0 00-1.7.7 4.3 4.3 0 00-1 1.3l-1.7-1.2a5 5 0 011.5-1.9 5.5 5.5 0 012.8-1 5.5 5.5 0 013.8.6 4 4 0 011.7 3l.7 6.1L93 17l.3 2zm-5.4-1a3.5 3.5 0 002-.8 1.6 1.6 0 00.5-1.5l-.2-1.7-2.2.2c-1.7.3-2.6 1-2.4 2v.5a1.5 1.5 0 00.7 1.1 2.5 2.5 0 001.6.2z' />
      <path d='M100.3 19.1a2.7 2.7 0 01-2-.7 2.7 2.7 0 01-.7-2l.2-7.5H96V6.6h1a1 1 0 001-.2 1.4 1.4 0 00.2-1V3.4h2.3v3.4h2.5V9h-2.5l-.2 8h2.3v2.2z' />
      <path d='M108.2 7.4l2.5.3-1.5 12.2-2.6-.3zm1.5-2a1.6 1.6 0 01-1-.6 1.3 1.3 0 01-.3-1v-.4a1.4 1.4 0 01.5-.9 1.6 1.6 0 011.2-.2 1.6 1.6 0 011.1.5 1.4 1.4 0 01.2 1v.4a1.3 1.3 0 01-.5 1 1.5 1.5 0 01-1.2.1z' />
      <path d='M118.6 22a6 6 0 01-2.1-1 4.7 4.7 0 01-1.4-1.7 5.9 5.9 0 01-.5-2.3 8.2 8.2 0 01.3-2.7A8.2 8.2 0 01116 12a5.8 5.8 0 011.6-1.6 4.7 4.7 0 012.1-.8 6.2 6.2 0 014.5 1.3 5 5 0 011.4 1.7 6.1 6.1 0 01.5 2.3 8.1 8.1 0 01-.3 2.6 8.2 8.2 0 01-1.2 2.5 6.2 6.2 0 01-1.6 1.6 5 5 0 01-2 .8 5.8 5.8 0 01-2.4-.2zm.6-2a2.9 2.9 0 002.3-.2 3.4 3.4 0 001.4-2.1l.6-2a3.3 3.3 0 00-.2-2.6 3.2 3.2 0 00-4-1.1 3.3 3.3 0 00-1.5 2.1l-.5 2a3.3 3.3 0 000 2.6 2.8 2.8 0 001.9 1.3z' />
      <path d='M128 24.6l5.6-11 2.3 1.2-.9 1.8h.1a4.2 4.2 0 011.8-1 3.5 3.5 0 012.3.5 3.7 3.7 0 012 2.5 5.3 5.3 0 01-.6 3.6l-3.6 7-2.3-1.2 3.4-6.7c1-1.7.7-2.9-.7-3.6a3.7 3.7 0 00-1-.3 2.7 2.7 0 00-.9 0 2 2 0 00-.8.3 1.9 1.9 0 00-.6.8l-3.8 7.3z' />
      <path d='M150.1 38.7a5.7 5.7 0 01-1.5-1.9 4.6 4.6 0 01-.5-2.1 6.3 6.3 0 01.6-2.3 8.8 8.8 0 011.4-2.2 9 9 0 012-1.8 6 6 0 012.2-.8 4.5 4.5 0 012.1.2 6 6 0 012.1 1.2 5.2 5.2 0 011.8 2.6 4.8 4.8 0 01-.2 2.8l-2.2-.6a2.5 2.5 0 00-.8-3.2 2.6 2.6 0 00-2.2-.7 3.5 3.5 0 00-2 1.3l-1.4 1.6a3.5 3.5 0 00-1 2.2 2.6 2.6 0 001 2 2.8 2.8 0 001.7.8 4 4 0 001.8-.4l.8 2a4.8 4.8 0 01-2.9.6 5.2 5.2 0 01-2.8-1.3z' />
      <path d='M159.3 48a6 6 0 01-1.1-2 5 5 0 01-.2-2.2 6 6 0 01.8-2.2 9.1 9.1 0 014-3.5 6 6 0 012.2-.4 4.9 4.9 0 012.2.5 6.3 6.3 0 013 3.5 4.8 4.8 0 01.2 2.2 6 6 0 01-.8 2.2 8.4 8.4 0 01-1.7 2 8.3 8.3 0 01-2.3 1.4 5.8 5.8 0 01-2.3.5 4.8 4.8 0 01-2.1-.5 6 6 0 01-1.8-1.5zm1.7-1.4a2.9 2.9 0 002 1 3.3 3.3 0 002.4-1l1.5-1.3a3.3 3.3 0 001.3-2.2 2.9 2.9 0 00-.7-2.1 2.9 2.9 0 00-2-1 3.3 3.3 0 00-2.4 1l-1.6 1.3a3.4 3.4 0 00-1.3 2.2 3 3 0 00.8 2.1z' />
      <path d='M165.4 55.5l10.7-6 1.3 2.1-1.8 1v.1a4.2 4.2 0 012 .3 3.5 3.5 0 011.8 1.6 3.6 3.6 0 01.4 3.1 5.3 5.3 0 01-2.6 2.7l-6.7 4-1.3-2.3 6.4-3.7q2.5-1.5 1.4-3.5a3.5 3.5 0 00-.6-.8 2.8 2.8 0 00-.8-.5 2 2 0 00-.9-.2 2 2 0 00-.9.3l-7.1 4z' />
      <path d='M173.5 71.9a2.7 2.7 0 010-2.1 2.6 2.6 0 011.5-1.4l7-2.8-.7-1.7 2-.8.4 1a1.1 1.1 0 00.6.7 1.4 1.4 0 001-.1l2-.8.7 2.1-3.1 1.2 1 2.4-2 .8-.9-2.4-7.5 3 .9 2.2-2 .8z' />
      <path d='M187.5 75.2l.6 2.5-12 2.8-.6-2.5zm2.5.8a1.6 1.6 0 01.1-1.2 1.4 1.4 0 01.9-.6h.3a1.3 1.3 0 011 0 2 2 0 01.6 2.3 1.4 1.4 0 01-.9.6h-.4a1.4 1.4 0 01-1 0 1.5 1.5 0 01-.6-1.1z' />
      <path d='M177.2 85.2l12.2-1.5.3 2.5-2 .3v.1a4.2 4.2 0 011.8 1 3.4 3.4 0 011 2.1 3.7 3.7 0 01-.9 3.1 5.4 5.4 0 01-3.4 1.5l-7.7 1-.3-2.5 7.4-1c1.9-.2 2.8-1.1 2.6-2.6a4.1 4.1 0 00-.3-1 2.7 2.7 0 00-.5-.8 2 2 0 00-.7-.6 2 2 0 00-1 0l-8.2 1z' />
      <path d='M180 107.6a4.5 4.5 0 01-.8-.6 3.3 3.3 0 01-.7-.7 3.3 3.3 0 01-.4-1 4.7 4.7 0 01-.1-1.3 3.7 3.7 0 011.4-3 5.3 5.3 0 013.6-.7l7.8.6-.2 2.6-7.4-.6c-2-.1-3 .6-3 2.1a4 4 0 000 1 2.4 2.4 0 00.4 1 2 2 0 001.5.8l8.2.7-.2 2.5-12.3-.9.2-2.6z' />
      <path d='M175.7 119.5a5.8 5.8 0 011-2.2 5 5 0 011.8-1.3 5.9 5.9 0 012.2-.6 8.2 8.2 0 012.7.4 8.3 8.3 0 012.4 1.1 5.8 5.8 0 011.7 1.6 4.9 4.9 0 01.7 2 6 6 0 01-.2 2.5 5.5 5.5 0 01-1.1 2.1 4.7 4.7 0 01-1.7 1.3 5.6 5.6 0 01-2.2.5 7.7 7.7 0 01-2.4-.3l-1-.3 2.4-8.1-.4-.1a3.3 3.3 0 00-2.4.2 3.1 3.1 0 00-1.6 2 3.5 3.5 0 000 2 4.3 4.3 0 001 1.7l-2 1a4.8 4.8 0 01-1.1-2.4 6.3 6.3 0 01.2-3.1zm10.4 3a3.1 3.1 0 000-1.3 2.7 2.7 0 00-.3-1 3.1 3.1 0 00-.8-1 4 4 0 00-1.2-.5h-.1l-1.6 5.5.2.1a3.5 3.5 0 002.4 0 2.5 2.5 0 001.4-1.9z' />
      <path d='M175.6 130.9a1.7 1.7 0 01-.9 1 1.6 1.6 0 01-1.2 0l-.3-.2a5.4 5.4 0 01-2-1.4 6.3 6.3 0 01-1.4-2.4l.7-1.8a12.7 12.7 0 00.9 1.7 5.8 5.8 0 001.2 1.3 1.4 1.4 0 01.8-.6 1.6 1.6 0 011 0l.4.2a1.6 1.6 0 01.9.8 1.7 1.7 0 010 1.4z' />
      <path d='M164.2 148.8a4.2 4.2 0 01-.4-1 2.7 2.7 0 01-.2-1 3.2 3.2 0 01.1-1 4.4 4.4 0 01.6-1.2 3.7 3.7 0 012.8-1.8 5.3 5.3 0 013.4 1.2l6.4 4.6-1.5 2-6-4.3q-2.4-1.6-3.8.3a3.6 3.6 0 00-.4.9 2.7 2.7 0 00-.2.9 2 2 0 00.2.8 1.8 1.8 0 00.6.8l6.7 4.7-1.5 2.1-10-7 1.5-2.2z' />
      <path d='M158 153.5l8.5 9-1.9 1.7-1.4-1.5v.1a4 4 0 01.2 2 3.5 3.5 0 01-1.2 2 3.7 3.7 0 01-3 1.1 5.3 5.3 0 01-3.2-1.8l-5.3-5.6 1.8-1.8 5.2 5.4c1.3 1.4 2.5 1.6 3.6.5a4 4 0 00.7-.8 2.8 2.8 0 00.3-.9 2 2 0 000-.9 2 2 0 00-.5-.8l-5.7-6z' />
      <path d='M142.8 166a5.8 5.8 0 012.3-.8 4.9 4.9 0 012.2 0 5.8 5.8 0 012 1.2 9.3 9.3 0 012.8 4.5 5.7 5.7 0 01.2 2.3 4.8 4.8 0 01-.8 2 6 6 0 01-1.8 1.6 5.7 5.7 0 01-2.2 1 4.5 4.5 0 01-2.2-.3 5.4 5.4 0 01-1.9-1 7.6 7.6 0 01-1.6-1.9l-.5-.8 7.1-4.5-.2-.4a3.2 3.2 0 00-2-1.5 3.1 3.1 0 00-2.5.6 3.5 3.5 0 00-1.3 1.4 4.1 4.1 0 00-.4 1.8l-2.1-.4a4.8 4.8 0 01.7-2.6 6.5 6.5 0 012.2-2.1zm5.9 9.2a3.2 3.2 0 00.9-.9 2.7 2.7 0 00.4-1 3.1 3.1 0 000-1.2 4 4 0 00-.5-1.2v-.2l-4.9 3.1.1.2a3.5 3.5 0 001.9 1.5 2.5 2.5 0 002-.3z' />
      <path d='M132.7 185.8l-2.4.8-.7-2h-.1a3.4 3.4 0 01-.7 2 3.8 3.8 0 01-1.8 1.4 4.2 4.2 0 01-4-.4 7.7 7.7 0 01-2.7-4.1 7.7 7.7 0 01-.3-5 4.3 4.3 0 012.8-2.7 3.8 3.8 0 012.3 0 3.5 3.5 0 011.7 1.1h.2l-2.2-6.4 2.4-.8zm-8.4-8.1a2.8 2.8 0 00-1.8 1.5 3.2 3.2 0 000 2.4l.7 2a3.3 3.3 0 001.5 2 2.8 2.8 0 002.3 0 3.2 3.2 0 001.7-1.2 1.8 1.8 0 00.3-1.7l-1.4-4a1.8 1.8 0 00-1.2-1.1 3.3 3.3 0 00-2.1 0z' />
      <path d='M114.2 178.4l2.2 12.1-2.6.5-.4-2.3a3.3 3.3 0 01-.9 1.8 3.4 3.4 0 01-2 1l-.8.2-.4-2.4 1-.2a4.6 4.6 0 002.2-1 1.6 1.6 0 00.6-1.5l-1.4-7.7z' />
      <path d='M105 192l-2.6.3-.9-12.3 2.6-.2zm-1.1 2.4a1.5 1.5 0 011.2.3 1.3 1.3 0 01.4.9v.4a1.4 1.4 0 01-.3 1 1.6 1.6 0 01-1.1.5 1.5 1.5 0 01-1.2-.3 1.3 1.3 0 01-.4-1v-.4a1.3 1.3 0 01.2-1 1.5 1.5 0 011.2-.4z' />
      <path d='M91.9 179.6a5.8 5.8 0 012.3.6 4.7 4.7 0 011.6 1.4 5.9 5.9 0 011 2.1 8.3 8.3 0 01.2 2.7 8.1 8.1 0 01-.6 2.6 6 6 0 01-1.3 2 4.8 4.8 0 01-1.8 1.1 5.7 5.7 0 01-2.4.3 5.8 5.8 0 01-2.3-.6 5 5 0 01-1.7-1.4 6 6 0 01-1-2.1 9 9 0 01.4-5.3 5.9 5.9 0 011.4-2 4.7 4.7 0 011.8-1.1 5.7 5.7 0 012.4-.3zm-.2 2.1a2.8 2.8 0 00-2.2.7 3.3 3.3 0 00-1 2.3l-.1 2a3.3 3.3 0 00.6 2.6 3.2 3.2 0 004.2.3 3.3 3.3 0 001-2.4l.2-2a3.4 3.4 0 00-.6-2.5 2.9 2.9 0 00-2-1z' />
      <path d='M82.3 178.6l-2.8 12-2.5-.6.5-2.3A3.3 3.3 0 0176 189a3.4 3.4 0 01-2.4.2l-.7-.2.6-2.4 1 .2a4.5 4.5 0 002.4 0 1.6 1.6 0 001.1-1.1l1.8-7.7z' />
      <path d='M66.5 189.2a1.6 1.6 0 011 .8 1.3 1.3 0 010 1l-.1.4a1.3 1.3 0 01-.7.8 2 2 0 01-2.2-.8 1.4 1.4 0 010-1l.2-.4a1.3 1.3 0 01.6-.8 1.6 1.6 0 011.2 0zm2-1.6l-2.5-.8 4-11.7 2.4.8z' />
      <path d='M62.4 171.8a2.7 2.7 0 011.5 1.6 2.8 2.8 0 01-.3 2l-3.5 6.7 1.7.9-1 1.9-.8-.5a1.2 1.2 0 00-1-.1 1.4 1.4 0 00-.6.7l-1 1.9-2-1 1.5-3-2.3-1.3 1-1.8 2.2 1.2 3.7-7.2-2.1-1 1-2z' />
      <path d='M44.5 177.3l.9 1.5-4.2 2.3-1.2-2.2zM53 166a5.8 5.8 0 011.7 1.7 4.9 4.9 0 01.7 2.1 5.8 5.8 0 01-.3 2.3 9.2 9.2 0 01-3 4.4 5.8 5.8 0 01-2.2 1 5 5 0 01-2.2 0 6 6 0 01-2.1-1 5.7 5.7 0 01-1.7-1.8 4.7 4.7 0 01-.6-2 5.8 5.8 0 01.3-2.3 8 8 0 011-2.1l.6-.8 6.9 5 .2-.4a3.3 3.3 0 00.7-2.4 3.1 3.1 0 00-1.5-2 3.5 3.5 0 00-1.8-.8 4.5 4.5 0 00-1.9.3l-.3-2a5 5 0 012.6-.4 6.5 6.5 0 013 1.2zm-6.3 8.8a3 3 0 001.2.6 2.8 2.8 0 001.1 0 3 3 0 001.1-.5 4.2 4.2 0 001-1l-4.6-3.4-.2.2a3.5 3.5 0 00-.7 2.3 2.5 2.5 0 001.1 1.8z' />
      <path d='M34 163.1a88.7 88.7 0 01-26.4-63 91.1 91.1 0 01.8-13l2.2.4a88.1 88.1 0 00-.8 12.6 86.6 86.6 0 0025.7 61.5z' />
    </RoundContainer>
    <path d='M131.1 122.7H95.8v-2.2h33.1V79.3l-3.5 1-.7-2 6.4-2v46.4z' />
    <path d='M95.7 122.7a1 1 0 01-.3 0l-29.3-8.3a1.1 1.1 0 01-.8-1V75a1 1 0 01.5-.8 1.1 1.1 0 011-.2L96 82.4a1.1 1.1 0 01.8 1v38.2a1.1 1.1 0 01-1.1 1.1zm-28.2-10.2l27.1 7.7v-36l-27-7.6z' />
    <path d='M95.6 122.7a1 1 0 01-.6-.2 1 1 0 01-.5-.9V83.5a1.1 1.1 0 01.8-1.1l29.3-8.3a1.1 1.1 0 011 .2 1 1 0 01.5.8v38.2a1.1 1.1 0 01-.8 1L96 122.8a1 1 0 01-.4 0zm1.1-38.4v35.9l27.1-7.7V76.6z' />
    <path d='M109.1 90.4h2.2v15.2h-2.2z' />
    <path d='M102.6 97h15.2v2.2h-15.2z' />
  </svg>
);

export default StampFormation;
