// vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../../../components/Accordion/AccordionItem/AccordionItem';
import IconArrow from '../../../images/IconArrow';

import {
  Title,
  CTAWrapper,
  CTAButton,
  AccordionWrapper,
  StyledPicture,
} from './FormationCard.styles';
import BuyTrainingButton from '../../../components/BuyTrainingButton';
import Button from '../../../components/Button';

const FormationCard = ({ title, children, to, fluid, price, id, registry }) => {
  const [open, setOpen] = useState(false);

  const handleToggleClick = () => setOpen(!open);

  return (
    <div>
      <StyledPicture fluid={fluid} alt='' role='presentation' />

      <AccordionWrapper>
        <AccordionItem
          renderTitle={<Title>{title}</Title>}
          open={open}
          onToggleClick={handleToggleClick}
        >
          {children}

          <CTAWrapper>
            <CTAButton to={to}>
              Plus de détails <IconArrow />
            </CTAButton>
          </CTAWrapper>
        </AccordionItem>
      </AccordionWrapper>

      {registry.type === 'ONLINE' && (
        <BuyTrainingButton
          outlined
          id={id}
          price={price}
          url={to}
          title={title}
        >
          {price > 0 ? 'Ajouter au panier' : 'S&apos;inscrire'}
        </BuyTrainingButton>
      )}

      {registry.type === 'EMAIL' && (
        <Button outlined href={registry.href}>
          S&apos;inscrire
        </Button>
      )}

      {registry.type === 'URL' && (
        <Button
          outlined
          href={registry.href}
          target='_blank'
          rel='noopener noreferrer'
        >
          S&apos;inscrire
        </Button>
      )}
    </div>
  );
};

FormationCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  fluid: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  registry: PropTypes.shape({
    type: PropTypes.string.isRequired,
    href: PropTypes.string,
  }).isRequired,
};

export default FormationCard;
