// vendors
import React from 'react';
import PropTypes from 'prop-types';
import FormationCard from '../FormationCard';
import { List, ListItem } from './FormationList.styles';
import BlockContent from '../../../components/BlockContent';

const FormationList = ({ items }) => {
  return (
    <List>
      {items.map((item) => (
        <ListItem>
          <FormationCard {...item}>
            <BlockContent blocks={item.rawDescription} />
          </FormationCard>
        </ListItem>
      ))}
    </List>
  );
};

FormationList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default FormationList;
