// vendors
import { Link } from 'gatsby';
import { em } from 'polished';
import styled from 'styled-components';
import Img from 'gatsby-image';
import breakpoints from '../../../styles/breakpoints';
import colors from '../../../styles/colors';
import { fontWeights } from '../../../styles/typography';
import breakpointsRange from '../../../utils/breakpointsRange';

export const Title = styled.h3`
  margin: 0;

  font-weight: ${fontWeights.body};

  ${breakpointsRange(
    [{ prop: 'fontSize', sizes: [20, 38] }],
    breakpoints.fonts
  )}
`;

export const CTAButton = styled(Link)`
  display: inline-block;
  padding: 1em 0;

  color: ${colors.azure};
  text-decoration: none;

  background: none;
  border: 0;

  cursor: pointer;

  appearance: none;

  ${breakpointsRange(
    [{ prop: 'fontSize', sizes: [15, 24] }],
    breakpoints.fonts
  )}

  svg {
    width: ${em(12, 24)};
    margin-left: ${em(15, 24)};
  }

  :hover,
  :focus {
    background-color: ${colors.veryLightPink};
  }
`;

export const CTAWrapper = styled.div`
  text-align: right;
`;

export const AccordionWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 26px;
`;

export const StyledPicture = styled(Img)``;
